import {
  mdiExclamationThick,
  mdiFlag,
  mdiViewDashboardVariant,
  mdiClipboardOutline,
  mdiCertificate,
  mdiScaleBalance,
  mdiAccountGroup,
  mdiOfficeBuilding,
  mdiFileDocument,
  mdiWrench,
  mdiWarehouse,
  mdiPowerPlug,
  mdiCog,
  mdiCogs,
  mdiAccount,
  mdiAccountCog,
  mdiClockTimeFiveOutline,
  mdiBell,
  mdiRss,
  mdiBriefcaseVariant,
  mdiCalendarMonth,
  mdiEmailOutline,
  mdiSpeedometer,
  mdiEyeCheck,
  mdiAlertDecagram,
  mdiAlertOutline,
  mdiShieldAlertOutline,
  mdiShieldLockOpenOutline,
  mdiAutoFix,
  mdiAccountMultiple,
  mdiCurrencyEur,
  mdiPackageVariantClosed,
  mdiShuffleVariant,
  mdiApplicationCog,
  mdiFilter,
  mdiTag,
  mdiFile,
  mdiTextBox,
  mdiOfficeBuildingCog,
  mdiHistory,
  mdiTranslate,
  mdiBullhorn,
} from "@mdi/js";

const FIREBASE_KEY =
  "BN4y-6Wf_rUy0IMXJSSxszmW0uQu0dUAtE1dkQZcdduyfV5Yr37AKPW19muRM73cdW2DvH_cs7L4GTfB4W_Dgc8";

const WIKI_URL = "wiki.ausem.io";

const ACCOUNT = -1;
const PREFERENCES = -2;
const DEVICES = -3;
const ABOUT = -4;
const LICENSE = -5;
const PUBLIC_ISSUES = -6;
const MAIN_PROFILE = -7;

const MAIN_DASHBOARD_SCREENS = 1;
const MAIN_ISSUES = 2;
const MAIN_PERSONS = 3;
const MAIN_ASSETS = 4;
const MAIN_DOCUMENTS = 5;
const MAIN_SERVICES = 6;
const MAIN_RISK_MANAGEMENT = 7;
const MAIN_AUDITS = 8;
const MAIN_WAREHOUSES = 9;
const MAIN_CONNECTORS = 10;
const MAIN_SETTINGS = 11;
const MAIN_ADMINISTRATION = 12;
const MAIN_IMPLEMENTATION = 13;
const ACTIVITIES = 14;
const ASSET_CATEGORIES = 15;
const ASSET_GROUPS = 18;
const ASSETS = 21;
const AUDITS = 32;
const AUTOMATED_ACTIONS = 36;
const BACKGROUND_JOBS = 39;
const CONNECTORS = 40;
const CONSEQUENCES = 42;
const CONTRACTS = 43;
const CONTROLS = 45;
const CONTROLS_MANAGEMENT = 46;
const DASHBOARD_SCREENS = 47;
const DATA_SETS = 48;
const DOCUMENT_SETTINGS = 50;
const DOCUMENTS = 51;
const EXTERNAL_EMAILS = 53;
const FIELDS = 55;
const HEALTH_CHECK = 57;
const HOLIDAYS = 58;
const IMPORT_ASSETS = 59;
const IMPORT_CONTRACTS = 60;
const IMPORT_CONTROLS = 61;
const IMPORT_DOCUMENTS = 62;
const IMPORT_FIELDS = 63;
const IMPORT_HOLIDAYS = 64;
const IMPORT_ISSUE_PRIORITIES = 65;
const IMPORT_ISSUE_STATES = 66;
const IMPORT_ISSUES = 67;
const IMPORT_PERSON_RELATIONS = 68;
const IMPORT_PERSONS = 69;
const IMPORT_PRICE_ITEMS = 70;
const IMPORT_PRICE_LISTS = 71;
const IMPORT_RULES = 72;
const IMPORT_SERVICES = 73;
const IMPORT_STANDARDS = 74;
const IMPORT_TAG_DEFINITIONS = 75;
const IMPORT_THREATS = 76;
const IMPORT_TRANSLATIONS = 77;
const IMPORT_USERS = 78;
const IMPORT_VULNERABILITIES = 79;
const IMPORT_WAREHOUSES = 80;
const INSTALLATION = 81;
const ISSUE_PRIORITIES = 82;
const ISSUE_STATES = 83;
const ISSUES = 84;
const KPIS = 93;
const LIKELIHOODS = 97;
const LINKAGES_SETTING = 98;
const MAIL_SERVER = 99;
const MAINTENANCE_PLAN = 100;
const MAPPERS = 101;
const NOTICE = 104;
const NOTIFICATIONS = 105;
const PERMISSIONS = 106;
const PERSON_CATEGORIES = 107;
const PERSON_RELATIONS = 109;
const PERSONS = 110;
const PLANNING_CALENDAR = 117;
const PRICE_ITEMS = 118;
const PRICE_LISTS = 121;
const PRIMARY_ASSETS = 123;
const PUBLIC_FORM = 125;
const REMINDERS = 126;
const REPORTS = 127;
const RISK_LEVELS = 128;
const RISKS = 129;
const ROLES = 130;
const RULES = 135;
const SERVICES = 138;
const SNIPPETS = 146;
const STANDARD_SOAS = 147;
const STANDARDS = 149;
const STANDBY_PLAN = 151;
const STATISTICS = 152;
const SUBSCRIPTIONS = 153;
const TAG_DEFINITIONS = 155;
const THREATS = 156;
const TRANSLATIONS = 158;
const USER_SETTINGS = 159;
const USERS = 160;
const VULNERABILITIES = 161;
const WAREHOUSES = 163;

const APP_MENU = Object.freeze([
  {
    perms_name: MAIN_PROFILE,
    multiple: true,
    noSetter: true,
    icon: mdiAccount,
    items: [
      {
        title_t: "profile.account",
        url: "account",
        wiki_link: "profile/account/account/",
        perms_name: ACCOUNT,
      },
      {
        title_t: "profile.preferences",
        url: "preferences",
        wiki_link: "profile/preferences/preferences/",
        perms_name: PREFERENCES,
      },
      {
        title_t: "profile.devices",
        url: "devices",
        wiki_link: "profile/devices/devices/",
        perms_name: DEVICES,
      },
      {
        title_t: "profile.about",
        url: "about",
        wiki_link: "profile/about/about/",
        perms_name: ABOUT,
      },
      {
        title_t: "license.license",
        url: "license",
        wiki_link: "profile/license/license/",
        perms_name: LICENSE,
      },
    ],
  },
  {
    perms_name: MAIN_DASHBOARD_SCREENS,
    title_t: "dashboard_screen.dashboard_screens",
    multiple: true,
    noSetter: true,
    icon: mdiViewDashboardVariant,
    items: [
      {
        title_t: "dashboard_screen.dashboard_screens",
        perms_name: DASHBOARD_SCREENS,
        icon: mdiViewDashboardVariant,
        url: "dashboard_screens",
        wiki_link: "dashboards/dashboards/dashboards/",
        tooltip: "Shift + B",
      },
      {
        title_t: "kpi.kpi",
        perms_name: KPIS,
        icon: mdiSpeedometer,
        url: "kpis",
        wiki_link: "dashboards/kpi/kpi/",
      },
    ],
  },
  {
    perms_name: MAIN_ISSUES,
    title_t: "issue.issues",
    multiple: true,
    noSetter: true,
    icon: mdiClipboardOutline,
    items: [
      {
        title_t: "issue.issues",
        perms_name: ISSUES,
        icon: mdiClipboardOutline,
        url: "issues",
        wiki_link: "issues/issues/issues/",
        tooltip: "Shift + I",
      },
      {
        title_t: "calendar.planning_calendar",
        perms_name: PLANNING_CALENDAR,
        url: "planning_calendar",
        wiki_link: "issues/planning_calendar/planningcalendar/",
        tooltip: "Shift + C",
        icon: mdiCalendarMonth,
      },
    ],
  },
  {
    perms_name: MAIN_PERSONS,
    title_t: "person.persons",
    multiple: true,
    noSetter: true,
    icon: mdiAccountGroup,
    items: [
      {
        title_t: "person.persons",
        perms_name: PERSONS,
        icon: mdiAccountGroup,
        url: "persons",
        wiki_link: "persons/persons/persons/",
        tooltip: "Shift + P",
      },
      {
        title_t: "category.categories",
        perms_name: PERSON_CATEGORIES,
        icon: mdiAccountCog,
        url: "person_categories",
        wiki_link: "persons/categories/categories/",
      },
      {
        title_t: "permission.permissions",
        perms_name: PERMISSIONS,
        icon: mdiEyeCheck,
        url: "permissions",
        wiki_link: "persons/permissions/permissions/",
      },
      {
        title_t: "role.roles",
        perms_name: ROLES,
        url: "roles",
        wiki_link: "persons/roles/roles/",
        icon: mdiAccountMultiple,
      },
      {
        title_t: "user.users",
        perms_name: USERS,
        icon: mdiAccount,
        url: "users",
        wiki_link: "persons/users/users/",
      },
    ],
  },
  {
    perms_name: MAIN_ASSETS,
    title_t: "asset.assets",
    multiple: true,
    noSetter: true,
    icon: mdiOfficeBuilding,
    items: [
      {
        title_t: "asset.assets",
        perms_name: ASSETS,
        url: "assets",
        wiki_link: "assets/assets/assets/",
        tooltip: "Shift + A",
        icon: mdiOfficeBuilding,
      },
      {
        title_t: "category.categories",
        perms_name: ASSET_CATEGORIES,
        icon: mdiOfficeBuildingCog,
        url: "asset_categories",
        wiki_link: "assets/categories/categories/",
      },
    ],
  },
  {
    perms_name: MAIN_DOCUMENTS,
    title_t: "document.documents",
    multiple: true,
    noSetter: true,
    icon: mdiFileDocument,
    items: [
      {
        title_t: "document.documents",
        perms_name: DOCUMENTS,
        icon: mdiFileDocument,
        url: "documents",
        wiki_link: "documents/documents/documents/",
        tooltip: "Shift + D",
      },
    ],
  },
  {
    perms_name: MAIN_SERVICES,
    title_t: "service.services",
    multiple: true,
    noSetter: true,
    icon: mdiWrench,
    items: [
      {
        title_t: "service.services",
        perms_name: SERVICES,
        icon: mdiWrench,
        url: "services",
        wiki_link: "services/services/services/",
        tooltip: "Shift + S",
      },
      {
        title_t: "contract.contracts",
        perms_name: CONTRACTS,
        icon: mdiBriefcaseVariant,
        url: "contracts",
        wiki_link: "services/contracts/contracts/",
      },
      {
        title_t: "calendar.maintenance_plan",
        perms_name: MAINTENANCE_PLAN,
        url: "maintenance_plan",
        wiki_link: "services/maintenance_plan/maintenance_plan/",
        icon: mdiCalendarMonth,
      },
      {
        title_t: "service_person.label.standby_plan",
        perms_name: STANDBY_PLAN,
        url: "standby_plan",
        wiki_link: "services/standby_plan/standby_plan/",
        icon: mdiCalendarMonth,
      },
      {
        title_t: "rule.rules",
        perms_name: RULES,
        icon: mdiCalendarMonth,
        url: "rules",
        wiki_link: "services/rules/rules/",
      },
    ],
  },
  {
    perms_name: MAIN_RISK_MANAGEMENT,
    title_t: "risk_management.risk_management",
    multiple: true,
    noSetter: true,
    icon: mdiAlertDecagram,
    items: [
      {
        title_t: "risk.label.risk_analysis",
        perms_name: RISKS,
        wiki_link: "risk_management/risks/risks/",
        icon: mdiCog,
        url: "risks",
      },
      {
        title_t: "primary_asset.primary_assets",
        perms_name: PRIMARY_ASSETS,
        wiki_link: "risk_management/primary_assets/primary_assets/",
        icon: mdiCog,
        url: "primary_assets",
      },
      {
        title_t: "asset_group.asset_groups",
        perms_name: ASSET_GROUPS,
        wiki_link: "risk_management/asset_groups/asset_groups/",
        icon: mdiCog,
        url: "asset_groups",
      },
      {
        title_t: "controls_management.controls_management",
        perms_name: CONTROLS_MANAGEMENT,
        wiki_link: "risk_management/controls_management/controls_management/",
        icon: mdiCog,
        url: "controls_management",
      },
      {
        title_t: "app_parts.linkages_setting",
        perms_name: LINKAGES_SETTING,
        wiki_link: "risk_management/linkages_setting/linkages_setting/",
        icon: mdiCog,
        url: "linkages_setting",
      },
      // {
      //   title_t: "soa.soas",
      //   perms_name: SOAS,
      //   wiki_link: "risk_management/soas/soas/",
      //   icon: mdiCog,
      //   url: "soas",
      // },
      {
        title_t: "threat.threats",
        perms_name: THREATS,
        icon: mdiShieldAlertOutline,
        url: "threats",
        wiki_link: "risk_management/threats/threats/",
      },
      {
        title_t: "vulnerability.vulnerabilities",
        perms_name: VULNERABILITIES,
        icon: mdiShieldLockOpenOutline,
        url: "vulnerabilities",
        wiki_link: "risk_management/vulnerabilities/vulnerabilities/",
      },
      {
        title_t: "control.controls",
        perms_name: CONTROLS,
        icon: mdiAlertOutline,
        url: "controls",
        wiki_link: "risk_management/controls/controls/",
      },
      {
        title_t: "consequence.consequences",
        perms_name: CONSEQUENCES,
        icon: mdiShieldAlertOutline,
        url: "consequences",
        wiki_link: "risk_management/consequences/consequences/",
      },
      {
        title_t: "likelihood.likelihoods",
        perms_name: LIKELIHOODS,
        icon: mdiShieldAlertOutline,
        url: "likelihoods",
        wiki_link: "risk_management/likelihoods/likelihoods/",
      },
      {
        title_t: "risk_level.risk_levels",
        perms_name: RISK_LEVELS,
        icon: mdiShieldAlertOutline,
        url: "risk_levels",
        wiki_link: "risk_management/risk_levels/risk_levels/",
      },
    ],
  },
  {
    perms_name: MAIN_AUDITS,
    title_t: "audit.audits",
    multiple: true,
    noSetter: true,
    icon: mdiCertificate,
    items: [
      {
        title_t: "audit.audits",
        perms_name: AUDITS,
        icon: mdiCertificate,
        url: "audits",
        wiki_link: "audits/audits/audits/",
      },
      {
        title_t: "standard_soa.standard_soas",
        perms_name: STANDARD_SOAS,
        icon: mdiCertificate,
        url: "standard_soas",
        wiki_link: "audits/standard_soas/standard_soas/",
      },
      {
        title_t: "standard.laws_standards",
        perms_name: STANDARDS,
        icon: mdiScaleBalance,
        url: "standards",
        wiki_link: "audits/standards/standards/",
      },
    ],
  },
  {
    perms_name: MAIN_WAREHOUSES,
    title_t: "warehouse.warehouses",
    multiple: true,
    noSetter: true,
    icon: mdiWarehouse,
    items: [
      {
        title_t: "warehouse.warehouses",
        perms_name: WAREHOUSES,
        icon: mdiWarehouse,
        url: "warehouses",
        wiki_link: "warehouses/warehouses/warehouses/",
        tooltip: "Shift + W",
      },
      {
        title_t: "price_list.price_lists",
        perms_name: PRICE_LISTS,
        icon: mdiCurrencyEur,
        url: "price_lists",
        wiki_link: "warehouses/price_lists/price_lists/",
      },
      {
        title_t: "price_item.price_items",
        perms_name: PRICE_ITEMS,
        icon: mdiPackageVariantClosed,
        url: "price_items",
        wiki_link: "warehouses/price_items/price_items/",
      },
    ],
  },
  {
    perms_name: MAIN_CONNECTORS,
    title_t: "connector.connectors",
    multiple: true,
    noSetter: true,
    icon: mdiPowerPlug,
    items: [
      {
        title_t: "connector.connectors",
        perms_name: CONNECTORS,
        icon: mdiPowerPlug,
        url: "connectors",
        wiki_link: "connectors/connectors/connectors/",
      },
      {
        title_t: "mapper.mappers",
        perms_name: MAPPERS,
        icon: mdiShuffleVariant,
        url: "mappers",
        wiki_link: "connectors/mappers/mappers/",
      },
      {
        title_t: "external_email.external_emails",
        perms_name: EXTERNAL_EMAILS,
        icon: mdiEmailOutline,
        url: "external_emails",
        wiki_link: "connectors/external_emails/external_emails/",
      },
    ],
  },
  {
    perms_name: MAIN_SETTINGS,
    title_t: "profile.settings",
    multiple: true,
    noSetter: true,
    icon: mdiAccountCog,
    items: [
      {
        title_t: "data_set.data_sets",
        perms_name: DATA_SETS,
        icon: mdiFilter,
        url: "data_sets",
        wiki_link: "settings/filters/filters/",
      },
      {
        title_t: "snippet.snippets",
        perms_name: SNIPPETS,
        icon: mdiTextBox,
        url: "snippets",
        wiki_link: "settings/snippets/snippets/",
      },
      {
        title_t: "notification.notifications",
        icon: mdiBell,
        url: "notifications",
        wiki_link: "settings/notifications/notifications/",
        perms_name: NOTIFICATIONS,
      },
      {
        title_t: "subscription.subscriptions",
        icon: mdiRss,
        url: "subscriptions",
        wiki_link: "settings/subscriptions/subscriptions/",
        perms_name: SUBSCRIPTIONS,
      },
      {
        title_t: "reminder.reminders",
        icon: mdiClockTimeFiveOutline,
        url: "reminders",
        wiki_link: "settings/reminders/reminders/",
        perms_name: REMINDERS,
      },
    ],
  },
  {
    perms_name: MAIN_ADMINISTRATION,
    title_t: "administration.administration",
    multiple: true,
    noSetter: true,
    icon: mdiCog,
    items: [
      {
        title_t: "issue_state.issue_states",
        url: "issue_states",
        wiki_link: "administration/issue_states/issue_states/",
        perms_name: ISSUE_STATES,
        icon: mdiFlag,
      },
      {
        title_t: "issue_priority.issue_priorities",
        url: "issue_priorities",
        wiki_link: "administration/issue_priorities/issue_priorities/",
        perms_name: ISSUE_PRIORITIES,
        icon: mdiExclamationThick,
      },
      {
        title_t: "tag_definition.tag_definitions",
        perms_name: TAG_DEFINITIONS,
        icon: mdiTag,
        url: "tag_definitions",
        wiki_link: "administration/tags/tags/",
      },
      {
        title_t: "person_relation.person_relations",
        url: "person_relations",
        wiki_link: "administration/person_relations/person_relations/",
        perms_name: PERSON_RELATIONS,
        icon: mdiAccountGroup,
      },
      {
        title_t: "field.fields",
        perms_name: FIELDS,
        icon: mdiApplicationCog,
        url: "fields",
        wiki_link: "administration/fields/fields/",
      },
      {
        title_t: "report.reports",
        perms_name: REPORTS,
        icon: mdiFile,
        url: "reports",
        wiki_link: "administration/reports/reports/",
      },
      {
        title_t: "automated_action.automated_actions",
        perms_name: AUTOMATED_ACTIONS,
        url: "automated_actions",
        wiki_link: "administration/automated_actions/automated_actions/",
        icon: mdiAutoFix,
      },
      {
        title_t: "translation.translations",
        perms_name: TRANSLATIONS,
        icon: mdiTranslate,
        url: "translations",
        wiki_link: "administration/translations/translations/",
      },
      {
        title_t: "implementation.menu.notice",
        url: "notice",
        wiki_link: "administration/notice/notice/",
        icon: mdiBullhorn,
        perms_name: NOTICE,
      },
      {
        title_t: "activity.activities",
        perms_name: ACTIVITIES,
        icon: mdiHistory,
        url: "activities",
        wiki_link: "administration/activities/activities/",
      },
    ],
  },
  {
    perms_name: MAIN_IMPLEMENTATION,
    title_t: "implementation.implementation",
    multiple: true,
    noSetter: true,
    icon: mdiCogs,
    items: [
      {
        title_t: "implementation.menu.installation",
        url: "installation",
        wiki_link: "implementation/installation/installation/",
        perms_name: INSTALLATION,
      },
      {
        title_t: "implementation.menu.statistics",
        url: "statistics",
        wiki_link: "implementation/statistics/statistics/",
        perms_name: STATISTICS,
      },
      {
        title_t: "implementation.menu.health_check",
        url: "health_check",
        wiki_link: "implementation/health_check/health_check/",
        perms_name: HEALTH_CHECK,
      },
      {
        title_t: "implementation.menu.mail_server",
        url: "mail_server",
        wiki_link: "implementation/mail_server/mail_server/",
        perms_name: MAIL_SERVER,
      },
      {
        title_t: "implementation.menu.public_form",
        url: "public_form",
        wiki_link: "implementation/public_form/public_form/",
        perms_name: PUBLIC_FORM,
      },
      {
        title_t: "implementation.menu.document_settings",
        url: "document_settings",
        wiki_link: "implementation/document_settings/document_settings/",
        perms_name: DOCUMENT_SETTINGS,
      },
      {
        title_t: "implementation.menu.user_settings",
        url: "user_settings",
        wiki_link: "implementation/user_settings/user_settings/",
        perms_name: USER_SETTINGS,
      },
      {
        title_t: "implementation.menu.holidays",
        url: "holidays",
        wiki_link: "implementation/holidays/holidays/",
        perms_name: HOLIDAYS,
      },
      {
        title_t: "implementation.menu.background_jobs",
        url: "background_jobs",
        wiki_link: "implementation/background_jobs/background_jobs/",
        perms_name: BACKGROUND_JOBS,
      },
    ],
  },
]);

const PATH_TO_PERMISSION = {
  ...{
    "assets/import": IMPORT_ASSETS,
    "contracts/import": IMPORT_CONTRACTS,
    "controls/import": IMPORT_CONTROLS,
    "documents/import": IMPORT_DOCUMENTS,
    "fields/import": IMPORT_FIELDS,
    "holidays/import": IMPORT_HOLIDAYS,
    "issue_priorities/import": IMPORT_ISSUE_PRIORITIES,
    "issue_states/import": IMPORT_ISSUE_STATES,
    "issues/import": IMPORT_ISSUES,
    "person_relations/import": IMPORT_PERSON_RELATIONS,
    "persons/import": IMPORT_PERSONS,
    "price_items/import": IMPORT_PRICE_ITEMS,
    "price_lists/import": IMPORT_PRICE_LISTS,
    "rules/import": IMPORT_RULES,
    "services/import": IMPORT_SERVICES,
    "standards/import": IMPORT_STANDARDS,
    "tag_definitions/import": IMPORT_TAG_DEFINITIONS,
    "threats/import": IMPORT_THREATS,
    "translations/import": IMPORT_TRANSLATIONS,
    "users/import": IMPORT_USERS,
    "vulnerabilities/import": IMPORT_VULNERABILITIES,
    "warehouses/import": IMPORT_WAREHOUSES,
    "public/issues": PUBLIC_ISSUES,
  },
  ...Object.fromEntries(
    APP_MENU.flatMap((m) => m.items.map((i) => [i.url, i.perms_name]))
  ),
};

const UNCHECKED_PARTS = Object.freeze([
  ACCOUNT,
  PREFERENCES,
  DEVICES,
  ABOUT,
  LICENSE,
  PUBLIC_ISSUES,
]);

const MODELS = Object.freeze([
  "AppliedControl",
  "Asset",
  "AssetCategory",
  "AssetGroup",
  "AssetLink",
  "AssetsIssue",
  "Audit",
  "AutomatedAction",
  "Category",
  "CategoryHierarchy",
  "CategoryThreat",
  "Cia",
  "Connector",
  "Consequence",
  "Contract",
  "Control",
  "ControlsManagement",
  "DashboardScreen",
  "Dashlet",
  "DataSet",
  "Document",
  "EntityDocument",
  "EntitySubscription",
  "ExternalEmail",
  "ExternalGuid",
  "Field",
  "Holiday",
  "IndoorLocation",
  "Issue",
  "IssueAssignee",
  "IssueLink",
  "IssueNote",
  "IssuePriority",
  "IssueState",
  "IssueStateTransition",
  "Kpi",
  "Likelihood",
  "Location",
  "Mapper",
  "Permission",
  "Person",
  "PersonAsset",
  "PersonCategory",
  "PersonRelation",
  "PersonRole",
  "PriceItem",
  "PriceItemsPriceList",
  "PriceItemsWarehouse",
  "PriceList",
  "PrimaryAsset",
  "Provision",
  "ProvisionQuestion",
  "Question",
  "Recommendation",
  "Reminder",
  "Report",
  "Requirement",
  "Risk",
  "RiskControl",
  "RiskLevel",
  "Role",
  "RoleIssueStateTransition",
  "Rule",
  "Section",
  "Service",
  "ServiceIssueStateSla",
  "ServicePerson",
  "Sla",
  "Snippet",
  "Soa",
  "SoaControl",
  "Standard",
  "StandardSoa",
  "StandardSoaProvision",
  "StandbyTime",
  "Subscription",
  "TagAssignment",
  "TagDefinition",
  "TemplateAssignment",
  "Threat",
  "ThreatVulnerability",
  "Translation",
  "User",
  "Vulnerability",
  "VulnerabilityControl",
  "Warehouse",
  "WarehouseBatch",
  "WarehouseTransaction",
  "WarehouseTransactionItem",
  "WorkReport",
]);

export {
  FIREBASE_KEY,
  WIKI_URL,
  APP_MENU,
  UNCHECKED_PARTS,
  MODELS,
  PATH_TO_PERMISSION,
};
